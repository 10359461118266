.ProgressBar{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
    counter-reset: steps 6;
    z-index: 10;
    margin-top: 8%;
}

li{
    display: inline-block;
    width: 200px;
    text-align: center;
    position: relative;
    color:rgb(194, 193, 193)
}

li:before{
    content: counter(steps);
    counter-increment: steps -1;
    display: block;
    width: 40px;
    height: 40px;
    border: 1px solid black;
    margin: 10px auto 10px auto;
    text-align: center;
    border-radius: 50%;
    line-height: 35px;
    outline: 4px inset rgb(194, 193, 193);
    z-index: 10;
    background-color: white;
    color: black;
}

li:after{
    content: '';
    width: 200px;
    height: 5px;
    background: #ddd;
    position: absolute;
    right: 50%;
    bottom: 60%;
    z-index: -10;
    transform: rotate(180deg);
}

li:last-child:after{
    content: none;
}

li.active{
    color: black;
}

li.active::after{
    background-color: #75f85e;
}
ul > li.active ~ li:after{
    background-color: #75f85e;
    color:green;
}
ul > li.active ~ li:before{
    outline: 4px inset #75f85e;
}
ul > li.active ~ li{
    color:green;
}





