input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.markercluster-map {
    height: 100%;
  }

  .Dropdown-control{
    background-color: #eeeeee !important;
    border:none !important
  }
  
.react-datepicker-popper{
  z-index: 1001 !important;
}

.loading_div{
    position: absolute;
    left: 45%;
    top: 50%;
    z-index: 10;

}

